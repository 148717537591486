<template>
  <div class="wrapper">
    <HeroLoader :loading="isLoadingPage">
      <BasePageHeader headline="Brancheområder" />

      <div class="grid" style="margin-top: 3rem;">
        <div class="col-12 col-md-6">
          <Box headline="Primær branche">
            <div class="box-body">
              <select :disabled="isLoading" v-model="form.primaryId" class="select" style="width: 100%"
                      @change="onSyncIndustries">
                <optgroup v-for="industry in industries"
                          :key="industry.id"
                          :label="industry.name"
                >
                  <option v-for="subindustry in industry.children"
                          :key="subindustry.id"
                          :value="subindustry.id"
                  >
                    {{ subindustry.name }}
                  </option>
                </optgroup>
              </select>
            </div>
          </Box>
        </div>
        <div class="col-12 col-md-6">
          <Box headline="Sekundære brancher (Op til 3)">
            <div class="box-body">
              <ul class="list">
                <li v-for="subindustry in form.secondaryList" :key="subindustry.id">
                  <p>{{ subindustry.parentName }}: {{ subindustry.name }}</p>
                  <button @click.prevent="removeSelectedSubindustry(subindustry.id)" :disabled="isLoading">
                    <font-awesome-icon icon="times"></font-awesome-icon>
                  </button>
                </li>
              </ul>

              <div>
                <select :disabled="isLoading" v-model="tempSecondary" class="select" style="width: 100%"
                        @change="onAddSecondaryIndustry" v-if="form.secondaryList.length < 3">
                  <option :value="null" disabled>Tilføj bibranche</option>
                  <optgroup v-for="industry in industries"
                            :key="industry.id"
                            :label="industry.name"
                  >
                    <option v-for="subindustry in getSubIndustries(industry)"
                            :key="subindustry.id"
                            :value="subindustry"
                    >
                      {{ subindustry.name }}
                    </option>
                  </optgroup>
                </select>
                <p v-else style="font-size: 0.85em; padding-top: 10px; text-align: right">Slet en sekundær branche for
                  at kunne vælge en anden.</p>
              </div>
            </div>
          </Box>
        </div>
      </div>
    </HeroLoader>
  </div>
</template>

<script>
/* eslint-disable */
import BasePageHeader from '@/containers/BasePageHeader.vue';
import HeroLoader from '@/components/HeroLoader.vue';
import useGlobalLoader from '@/composables/useGlobalLoader';
import BaseField from '@/components/BaseField.vue';
import BaseInput from '@/components/BaseInput.vue';
import BaseColumn from '@/components/BaseColumn.vue';
import BaseButton from '@/components/BaseButton.vue';
import BaseTextEditor from '@/components/Header/BaseTextEditor.vue';
import Info from '@/components/Info.vue';
import Box from '@/components/Box.vue';
import useAuth from '@/composables/useAuth';
import Message from '@/helpers/Message';
import ZipcodeSelector from '@/containers/ZipcodeSelector.vue';
import FileUpload from '@/containers/FileUpload.vue';
import CompanyLogo from '@/components/CompanyLogo.vue';
import BaseImage from '@/components/BaseImage';
import CompanyImage from '@/views/Admin/components/CompanyImage';
import InfoBox from '@/components/InfoBox';
import BaseTextarea from '@/components/BaseTextarea';
import TextareaAutosize from '@/components/TextareaAutosize';
import AdminCompanySkillService from '@/services/Admin/AdminCompanySkillService';
import useIndustries from '@/composables/useIndustries';
import AdminCompanyIndustryService from '@/services/Admin/AdminCompanyIndustryService';
import Multiselect from '@vueform/multiselect';
import BaseSelect from '@/components/BaseSelect';

export default {
  components: {
    BaseSelect,
    TextareaAutosize,
    Multiselect,
    BaseTextarea,
    InfoBox,
    CompanyImage,
    BaseImage,
    CompanyLogo,
    FileUpload,
    ZipcodeSelector,
    // eslint-disable-next-line vue/no-unused-components
    Box,
    // eslint-disable-next-line vue/no-unused-components
    Info,
    BaseTextEditor,
    BaseButton,
    BaseColumn,
    BaseInput,
    BaseField,
    HeroLoader,
    // eslint-disable-next-line vue/no-unused-components
    BasePageHeader
  },
  data() {
    return {
      form: {
        primaryId: null,
        secondaryList: []
      },
      selectedIndustries: {
        primary: null,
        secondaryList: null
      },
      tempSecondary: null
    };
  },
  setup() {
    const {
      company
    } = useAuth();

    const {
      industries,
      fetchIndustries
    } = useIndustries();

    const {
      startLoading,
      stopLoading,
      startPageLoading,
      stopPageLoading,
      isLoading,
      isLoadingPage
    } = useGlobalLoader();

    return {
      startLoading,
      stopLoading,
      isLoadingPage,
      isLoading,
      startPageLoading,
      stopPageLoading,
      company,
      industries,
      fetchIndustries
    };
  },
  async mounted() {
    this.startPageLoading();
    await Promise.all([this.fetchIndustries(), this.loadCompanyIndustries()]);
    this.stopPageLoading();
  },
  methods: {
    async removeSelectedSubindustry(id) {
      this.form.secondaryList = this.form.secondaryList.filter(item => item.id !== id);
      await this.onSyncIndustries();
    },
    getSubIndustries(industry) {
      return industry.children.filter(item => {
        if (this.form.primaryId === item.id) {
          return false;
        }
        return this.form.secondaryList.findIndex(i => i.id === item.id) === -1;
      });
    },
    async onAddSecondaryIndustry() {
      this.form.secondaryList.push(this.formatSecondaryIndustry(this.tempSecondary));
      this.tempSecondary = null;
      await this.onSyncIndustries();
    },
    formatSecondaryIndustry(subindustry) {
      return {
        id: subindustry.id,
        name: subindustry.name,
        parentName: subindustry.parent.name
      };
    },
    async onSyncIndustries() {
      this.startLoading('Gemmer');
      Message.clear();

      const primary = this.form.primaryId;
      const secondary = this.form.secondaryList.map(item => item.id);

      const request = {
        primary,
        secondary
      }

      try {
        const payload = await AdminCompanyIndustryService.sync(this.company.id, request);
        await this.loadCompanyIndustries();
        Message.httpSuccess(payload);
      } catch (e) {
        Message.httpErrors(e);
      } finally {
        this.stopLoading();
      }
    },
    async loadCompanyIndustries() {
      try {
        const payload = await AdminCompanyIndustryService.all(this.company.id);
        this.selectedIndustries.primary = payload?.data?.data.primary;
        this.selectedIndustries.secondaryList = payload?.data?.data.secondary;

        if (this.selectedIndustries.primary) {
          this.form.primaryId = this.selectedIndustries.primary.id;
        }
        if (this.selectedIndustries.secondaryList) {
          this.form.secondaryList = this.selectedIndustries.secondaryList.map(item => this.formatSecondaryIndustry(item));
        }
      } catch (e) {
        Message.httpError(e);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.box-body {
  padding: 15px;
}

.list {
  list-style: none;

  li {
    margin: 5px 0;
    padding: 10px;
    font-size: 0.9em;
    background: #eee;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;

    button {
      cursor: pointer;
    }
  }
}


.flip-list-move {
  transition: transform 0.5s;
}

.no-move {
  transition: transform 0s;
}

.ghost {
  opacity: 0.3 !important;
}

.list-group {
  padding-top: 15px;
}

.list-group-item {
  cursor: move;

  ::v-deep(.infoBox__content) {
    padding-right: 20px;
  }
}

.delete {
  position: absolute;
  top: 3px;
  right: 3px;
  background: #BE6143;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 2;
  font-size: 9px;
  color: #fff;
}
</style>
