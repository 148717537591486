<template>
  <div class="wrapper">
    <HeroLoader :loading="isLoadingPage">
      <BasePageHeader headline="Kompetencer"/>

      <Box headline="Tilføj ny kompetence" style="max-width: 650px; margin-top: 3rem;">
        <div style="padding: 15px">
          <div v-if="skills.length < maxItems">
            <BaseField label="Overskrift">
              <BaseInput v-model="form.title"
                         placeholder="Indtast titel af kompetence"/>
            </BaseField>
            <BaseField label="Beskrivelse">
              <BaseTextarea v-model="form.description"
                            autosize
                            placeholder="Indtast beskrivelse af kompetence"
                            :max-length="250"
              />
            </BaseField>
            <BaseButton :loading="isCreating"
                        type="info"
                        style="margin-top: 10px;"
                        @click.prevent="onCreateSkill">
              Tilføj
            </BaseButton>
          </div>
          <p v-else>
            Der må max tilføjes {{ maxItems }} kompetencer.
          </p>
        </div>
      </Box>

      <br/><br/>

      <BaseSection headline="Kompetencer">
        <div v-if="skills.length > 0">
          <p style="font-size: 0.9em; margin-bottom: 5px;">Træk i kompetencerne for at tilpasse
            visningen.</p>
          <draggable
            :list="skills"
            item-key="id"
            class="grid list-group"
            :disabled="isLoading"
            ghost-class="ghost"
            :animation="150"
            :component-data="{ tag: 'ul', name: 'flip-list', type: 'transition' }"
            @start="dragging = true"
            @end="onUpdateOrder"
          >
            <template #item="{ element }">
              <InfoBox class="list-group-item col-12 col-sm-6">
                <strong>
                  <p v-html="element.title"></p>
                </strong>
                <p v-html="element.description"></p>
                <button class="delete" @click.prevent="onDelete(element.id)" :disabled="isLoading">
                  <font-awesome-icon icon="times"></font-awesome-icon>
                </button>
              </InfoBox>
            </template>
          </draggable>
        </div>
        <p v-else style="font-size: 0.9em;">
          Der er ikke oprettet nogen kompetencer.
        </p>
      </BaseSection>
    </HeroLoader>
  </div>
</template>

<script>
/* eslint-disable */
import BasePageHeader from '@/containers/BasePageHeader.vue';
import HeroLoader from '@/components/HeroLoader.vue';
import useGlobalLoader from '@/composables/useGlobalLoader';
import BaseField from '@/components/BaseField.vue';
import BaseInput from '@/components/BaseInput.vue';
import BaseColumn from '@/components/BaseColumn.vue';
import BaseButton from '@/components/BaseButton.vue';
import BaseTextEditor from '@/components/Header/BaseTextEditor.vue';
import Info from '@/components/Info.vue';
import Box from '@/components/Box.vue';
import useAuth from '@/composables/useAuth';
import Message from '@/helpers/Message';
import ZipcodeSelector from '@/containers/ZipcodeSelector.vue';
import FileUpload from '@/containers/FileUpload.vue';
import CompanyLogo from '@/components/CompanyLogo.vue';
import draggable from 'vuedraggable';
import BaseImage from '@/components/BaseImage';
import CompanyImage from '@/views/Admin/components/CompanyImage';
import InfoBox from '@/components/InfoBox';
import BaseTextarea from '@/components/BaseTextarea';
import TextareaAutosize from '@/components/TextareaAutosize';
import AdminCompanySkillService from '@/services/Admin/AdminCompanySkillService';
import BaseSection from '@/components/BaseSection.vue';
import BaseLabel from '../../components/BaseLabel';

export default {
  components: {
    BaseLabel,
    BaseSection,
    TextareaAutosize,
    BaseTextarea,
    InfoBox,
    CompanyImage,
    BaseImage,
    CompanyLogo,
    FileUpload,
    draggable,
    ZipcodeSelector,
    // eslint-disable-next-line vue/no-unused-components
    Box,
    // eslint-disable-next-line vue/no-unused-components
    Info,
    BaseTextEditor,
    BaseButton,
    BaseColumn,
    BaseInput,
    BaseField,
    HeroLoader,
    // eslint-disable-next-line vue/no-unused-components
    BasePageHeader
  },
  data() {
    return {
      form: {
        description: '',
        title: ''
      },
      isCreating: false,
      enabled: true,
      skills: [],
      dragging: false,
      maxItems: 0
    };
  },
  setup() {
    const {
      company
    } = useAuth();

    const {
      startLoading,
      stopLoading,
      startPageLoading,
      stopPageLoading,
      isLoading,
      isLoadingPage
    } = useGlobalLoader();

    return {
      startLoading,
      stopLoading,
      isLoadingPage,
      isLoading,
      startPageLoading,
      stopPageLoading,
      company
    };
  },
  async mounted() {
    this.startPageLoading();
    await this.loadCompanySkills();
    this.stopPageLoading();
  },
  methods: {
    async onUpdateOrder() {
      this.dragging = false;
      Message.clear();
      this.startLoading('Opdaterer rækkefølge');

      const skills = this.skills.map((skill, index) => ({
        id: skill.id,
        order: index
      }));

      try {
        await AdminCompanySkillService.updateOrders(this.company.id, skills);
        // Message.httpSuccess(payload);
      } catch (e) {
        Message.httpError(e);
        await this.loadCompanySkills();
      } finally {
        this.stopLoading();
      }
    },
    sort() {
      this.list = this.list.sort((a, b) => a.order - b.order);
    },
    async onCreateSkill() {
      this.isCreating = true;
      this.startLoading('Opretter kompetence');
      Message.clear();

      try {
        const payload = await AdminCompanySkillService.store(this.company.id, {
          description: this.form.description,
          title: this.form.title
        });
        this.form.description = '';
        this.form.title = '';
        await this.loadCompanySkills();
        Message.httpSuccess(payload);
      } catch (e) {
        Message.httpErrors(e);
      } finally {
        this.stopLoading();
        this.isCreating = false;
      }
    },
    async onDelete(id) {
      if (!window.confirm('Vil du slette denne kompetence?')) {
        return;
      }
      Message.clear();
      this.startLoading('Sletter kompetence');

      try {
        const payload = await AdminCompanySkillService.delete(this.company.id, id);
        await this.loadCompanySkills();
        Message.httpSuccess(payload);
      } catch (e) {
        Message.httpError(e);
      } finally {
        this.stopLoading();
      }
    },
    async loadCompanySkills() {
      try {
        const payload = await AdminCompanySkillService.all(this.company.id);
        this.skills = payload?.data?.data;
        this.maxItems = payload?.data?.maxItems || 0;
      } catch (e) {
        Message.httpError(e);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.button {
  margin-top: 35px;
}

.flip-list-move {
  transition: transform 0.5s;
}

.no-move {
  transition: transform 0s;
}

.ghost {
  opacity: 0.3 !important;
}

.list-group {
  padding-top: 15px;
}

.list-group-item {
  cursor: move;

  ::v-deep(.infoBox__content) {
    padding-right: 20px;
  }
}

.delete {
  position: absolute;
  top: 3px;
  right: 3px;
  background: #BE6143;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 2;
  font-size: 9px;
  color: #fff;
}
</style>
