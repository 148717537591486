<template>
  <form @submit.prevent="onComment" class="commentForm">
    <BaseTextarea class="commentForm__field"
                  v-model="form.comment"
                  autosize
                  placeholder="Skriv kommentar"
    />
    <div>
      <BaseButton label="Kommentér" :loading="isSaving"/>
      <slot/>
    </div>
  </form>
</template>

<script>
import BaseTextarea from '@/components/BaseTextarea.vue';
import BaseButton from '@/components/BaseButton.vue';
import CommentService from '@/services/CommentService';
import Message from '@/helpers/Message';

export default {
  components: {
    BaseButton,
    BaseTextarea
  },
  props: {
    commentableType: String,
    commentableId: Number,
    parentCommentId: Number
  },
  emits: ['created'],
  data() {
    return {
      isSaving: false,
      form: {
        comment: ''
      }
    };
  },
  methods: {
    async onComment() {
      this.isSaving = true;
      const parentId = (this.parentCommentId === null || this.parentCommentId === undefined) ? null : this.parentCommentId;
      try {
        const payload = await CommentService.store(this.commentableType, this.commentableId, this.form.comment, parentId);
        this.form.comment = '';
        this.$emit('created');
        Message.httpSuccess(payload);
      } catch (e) {
        Message.httpErrors(e);
      } finally {
        this.isSaving = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@use "~@/styles/config/_index.scss" as config;

.commentForm {
  display: flex;
  width: 100%;
  flex-direction: column;

  @include config.md {
    flex-direction: row;
  }

  &__field {
    flex-grow: 1;

    @include config.md {
      margin-right: 10px;
    }

    ::v-deep textarea {
      padding-bottom: 10px;
    }
  }
}
</style>
